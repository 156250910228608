import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser } from "../../slices/user";
import TableContainer from "../../Components/Common/TableContainer";
import { useMemo } from "react";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { UpdateAiSubCategory, getBlogsCategory, getToolsSubCategory } from "../../slices/blogs";
import SubcategoryModal from "./SubcategoryModal";

const customerstatus = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "inactive" },
];
const ToolSubCategory = () => {
  document.title = "Tool Subcategory | FindMyAITool";

  const dispatch = useDispatch();
  const { toolSubCategoryCount, toolSubCategoryData, toolLoader,toolCategoryData } = useSelector(
    (state) => state.Tool
  );  const categoriesData = [
    {
      value: "",
      label: "All",
    },
    ...toolCategoryData
      ?.filter((item) => item?.status === "active")
      .map((item) => {
        return {
          value: item?._id,
          label: item?.name,
        };
      }),
  ];
  const [customerStatus, setcustomerStatus] = useState(customerstatus[0]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userStatus, setuserStatus] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [values, setValues] = useState({});
  const [rowId, setRowId] = useState("");
  const [additionalstatus, setAdditionalstatus] = useState(categoriesData[0]);
  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = useState(10);

  const handleOnChangeLimit =(value)=>{
    setPage(1);
    setLimit(value);
  }
  const nPages = Math.ceil(toolSubCategoryCount / limit);

  const onClickOpenAddModal = () => {
    setAddModal(true);
    setRowId("");
  };
  useEffect(() => {
    dispatch(getBlogsCategory({ limit: 30 }));
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus , additionalstatus.value]);
  useEffect(() => {
    setSearchValue("");
  }, [customerStatus]);
  useEffect(() => {
    const params = {};

    if (customerStatus?.value) {
      params.status = customerStatus.value;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd()	;
    }
    if (page) {
      params.page = page;
    }  if (additionalstatus?.value) {
      params.aiToolCategoryId = additionalstatus.value;
    }
    if (limit) {
      params.limit = limit;
    }    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getToolsSubCategory({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getToolsSubCategory({
          ...params,
        })
      );
    }
  }, [customerStatus, searchValue, page , additionalstatus.value , limit]);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const onClickDelete = (status) => {
    const data1 = {
      id: status?._id,
      status: status?.status === "active" ? "inactive" : "active",
    };

    setuserStatus(data1);
    setDeleteModal(true);
  };
  const handleOnEdit = (item) => {
    setRowId(item?._id);
    const categorydata = {
      label: item?.aiToolCategoryId?.name,
      value: item?.aiToolCategoryId?._id,
    };
    setValues({
      ...values,
      name: item?.name,
      category: categorydata,
      description: item?.description,
      metaTitle: item?.metaTitle || "",
      metaDescription: item?.metaDescription || "",
      metaKeywords: item?.metaKeywords || "",
      metaImg: item?.metaImg,
    });
    setAddModal(true);
  };
  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: (row) => row?.name ?? "-",

      filterable: false,
    },
    {
      Header: "Category Name",
      accessor: (row) => row?.aiToolCategoryId?.name ?? "-",
      filterable: false,
    },

    {
      Header: "Tool Count",
      Cell: (cellProps) => <>{cellProps?.row?.original?.aiToolCount || 0}</>,
    },
    {
      Header: "Created Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.createdAt)}</>
      ),
    },
    {
      Header: "Updated Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.updatedAt)}</>
      ),
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (cellProps) => {
        switch (cellProps?.row?.original.status) {
          case "active":
            return (
              <span className="badge text-uppercase badge-soft-success">
                Active
              </span>
            );
          case "inactive":
            return (
              <span className="badge text-uppercase badge-soft-danger">
                Deactivate
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                Unknown
              </span>
            );
        }
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="btn btn-soft-secondary btn-sm dropdown"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => {
                  handleOnEdit(cellProps?.row?.original);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                Edit
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  onClickDelete(cellProps?.row?.original);
                }}
              >
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                {cellProps?.row?.original.status === "active"
                  ? "Deactivate"
                  : "Active"}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ]);
  const handleDeleteUser = () => {
    setLoader(true)

    dispatch(
      UpdateAiSubCategory({ id: userStatus.id, status: userStatus.status })
    )
      .then((res) => {
        if (res?.payload.success === true) {
          if (userStatus?.status !== "active") {
            toast.success("Deactive successfully");
          } else {
            toast.success("Active successfully");
          }
          setDeleteModal(false);
          setLoader(false)

          dispatch(
            getToolsSubCategory({
              page,
              status: customerStatus?.value,
              aiToolCategoryId : additionalstatus.value,
              search :searchValue?.trimEnd()
            })
          );
        } else {
          setLoader(false)
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false)
        toast.error(err?.data?.message);
      });
  };

  return (
    <>
      {addModal && (
        <SubcategoryModal
          addModal={addModal}
          setAddModal={setAddModal}
          values={values}
          setValues={setValues}
          rowId={rowId}
          page={page}
        />
      )}
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          text={userStatus?.status !== "active" ? "Deactive" : "Active"}
          onDeleteClick={() => handleDeleteUser()}
          onCloseClick={() => setDeleteModal(false)}
          loader={loader}
        />
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Tool Subcategory
                  </h5>
              {   toolSubCategoryCount > 10 &&  <div className="flex-shrink-0">
                    <div className="d-flex gap-2 flex-wrap">
                      Show
                      <select name="pagination" style={{width:"70px"}}  value={limit}       onChange={(e) => handleOnChangeLimit(Number(e.target.value))}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </div>
                  </div>}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {toolLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {toolSubCategoryData &&
                      toolSubCategoryData?.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={toolSubCategoryData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          isCustomerFilter={true}
                          customerstatus={customerstatus}
                          setcustomerStatus={setcustomerStatus}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-borderless"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Subcategory..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          isPagination={
                           ( toolSubCategoryCount > 10 ) ? true : false
                          }
                          iscreated={true}
                          addbuttontext={"Add Subcategory"}
                          onClickOpenAddModal={onClickOpenAddModal}
                          isAdditionalStatus={true}
                          additionalstatus={additionalstatus}
                          setAdditionalstatus={setAdditionalstatus}
                          AdditionalOption={categoriesData}
                          totalDataCount={toolSubCategoryCount}
                          ispaginationshow={toolSubCategoryCount > 10 && limit <toolSubCategoryCount ? true : false }

                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={true}
                            customPageSize={0}
                            customerstatus={customerstatus}
                            setcustomerStatus={setcustomerStatus}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-borderless"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Subcategory..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            iscreated={true}
                            addbuttontext={"Add Subcategory"}
                            onClickOpenAddModal={onClickOpenAddModal}
                            isAdditionalStatus={true}
                            additionalstatus={additionalstatus}
                            setAdditionalstatus={setAdditionalstatus}
                            AdditionalOption={categoriesData}
                          />
                      
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ToolSubCategory;
