import React, { useEffect } from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../slices/dashboard";

import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Widgets = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { loader, dashboardData } = useSelector((state) => state.Dashboard);
  useEffect(() => {
    dispatch(getDashboard())
      .then((res) => {
        if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");

          localStorage.clear();
          sessionStorage.clear();
          history("/login");
        }
      })
      .catch((err) => {
      });
  }, []);

  return (
    <React.Fragment>
      <h4 className="fs-16 mb-4">Users List</h4>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <Link to="/user">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total User
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        end={dashboardData?.totalNumberOfUsers}
                        duration={4}
                      />
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                    <i className={"ri-parent-line" + " text-success"}></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <Link to="/user">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Active Users
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <CountUp
                      start={0}
                      end={dashboardData?.numberOfActiveUsers}
                      duration={4}
                    />
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                    <i className={"ri-user-heart-line" + " text-success"}></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card Card className="card-animate">
          <CardBody>
            <Link to="/user">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    New Users
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <CountUp
                      start={0}
                      end={dashboardData?.numberOfNewUsersWithin7Days}
                      duration={4}
                    />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                    <i
                      className={"ri-account-circle-line" + " text-success"}
                    ></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>{" "}
      <h4 className="fs-16 mb-4">Blogs List</h4>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <Link to="/blogs">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Blogs
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        end={dashboardData?.numberOfBlogs}
                        duration={4}
                      />
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-info"}>
                    <i className={"ri-apps-line" + " text-info"}></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <Link to="/blogs-category">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Pending Approval
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <CountUp
                      start={0}
                      end={dashboardData?.numberOfPendingBlogs}
                      duration={4}
                    />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-info"}>
                    <i className={"ri-git-commit-line" + " text-info"}></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <Link to="/blogs">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Approved Blogs
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <CountUp
                      start={0}
                      end={dashboardData?.numberOfApprovedBlogs}
                      duration={4}
                    />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-info"}>
                    <i className={"ri-git-merge-line" + " text-info"}></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>{" "}
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <Link to="/blogs">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Rejected Blogs
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary ">
                    <CountUp
                      start={0}
                      end={dashboardData?.numberOfRejectedBlogs}
                      duration={4}
                    />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-info"}>
                    <i className={"ri-git-merge-line" + " text-info"}></i>
                  </span>
                </div>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>{" "}
      {/* <h4 className="fs-16 mb-4">GPTs List</h4>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
          <Link to="/gpts" >

            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total GPTs
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary ">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dashboardData?.totalApps}
                      duration={4}
                    />
                  </span>
                </h4>
           
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                  <i className={" ri-camera-lens-line" + " text-success"}></i>
                </span>
              </div>
            </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
          <Link to="/gpts-category" >

            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  GPT Category
                </p>
              </div>
           
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary ">
                  <CountUp
                    start={0}
                    end={dashboardData?.countAppMainCategories}
                    duration={4}
                  />
                </h4>
             
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                  <i className={"ri-git-commit-line" + " text-success"}></i>
                </span>
              </div>
            </div>
            </Link>
          </CardBody>
        </Card>
      </Col> */}
      {/* <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
          <Link to="/gpts-subcategory" >

            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  GPT Subcategorie
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary ">
                  <CountUp
                    start={0}
                    end={dashboardData?.countAppCategories}
                    duration={4}
                  />
                </h4>
                <Link to="#" className="text-decoration-underline">
                   {item.link}
                 </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                  <i className={"ri-git-merge-line" + " text-success"}></i>
                </span>
              </div>
            </div>
            </Link>
          </CardBody>
        </Card>
      </Col> */}
    </React.Fragment>
  );
};

export default Widgets;






