export function FacebookWhiteIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_156_677)">
        <path
          d="M10.3936 18V9.78996H13.1482L13.5615 6.58941H10.3936V4.54632C10.3936 3.61998 10.6498 2.98869 11.9796 2.98869L13.673 2.98799V0.125307C13.3802 0.0872508 12.3749 0 11.2049 0C8.76181 0 7.08919 1.49127 7.08919 4.22934V6.58941H4.32617V9.78996H7.08919V18H10.3936Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_156_677">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function TwitterWhiteIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_156_671)">
        <path
          d="M18 3.41887C17.3306 3.7125 16.6174 3.90712 15.8737 4.00162C16.6388 3.54487 17.2226 2.82712 17.4971 1.962C16.7839 2.38725 15.9964 2.68763 15.1571 2.85525C14.4799 2.13413 13.5146 1.6875 12.4616 1.6875C10.4186 1.6875 8.77387 3.34575 8.77387 5.37863C8.77387 5.67113 8.79862 5.95237 8.85938 6.22012C5.7915 6.0705 3.07687 4.60013 1.25325 2.36025C0.934875 2.91263 0.748125 3.54488 0.748125 4.2255C0.748125 5.5035 1.40625 6.63637 2.38725 7.29225C1.79437 7.281 1.21275 7.10888 0.72 6.83775V6.87825C0.72 8.6715 1.99912 10.161 3.6765 10.5041C3.37612 10.5863 3.04875 10.6256 2.709 10.6256C2.47275 10.6256 2.23425 10.6121 2.01038 10.5626C2.4885 12.024 3.84525 13.0984 5.4585 13.1332C4.203 14.1154 2.60888 14.7071 0.883125 14.7071C0.5805 14.7071 0.29025 14.6936 0 14.6565C1.63462 15.7106 3.57188 16.3125 5.661 16.3125C12.4515 16.3125 16.164 10.6875 16.164 5.81175C16.164 5.64862 16.1584 5.49113 16.1505 5.33475C16.8829 4.815 17.4982 4.16587 18 3.41887Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_156_671">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function LinkedinWhiteIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2008 16.2V10.926C16.2008 8.33405 15.6428 6.35405 12.6188 6.35405C11.1608 6.35405 10.1888 7.14605 9.79278 7.90205H9.75678V6.58805H6.89478V16.2H9.88278V11.43C9.88278 10.17 10.1168 8.96405 11.6648 8.96405C13.1948 8.96405 13.2128 10.386 13.2128 11.502V16.182H16.2008V16.2ZM2.03478 6.58805H5.02278V16.2H2.03478V6.58805ZM3.52878 1.80005C2.57478 1.80005 1.80078 2.57405 1.80078 3.52805C1.80078 4.48205 2.57478 5.27405 3.52878 5.27405C4.48278 5.27405 5.25678 4.48205 5.25678 3.52805C5.25678 2.57405 4.48278 1.80005 3.52878 1.80005Z"
        fill="white"
      />
    </svg>
  );
}
export function InstagramWhiteIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_156_684)">
        <path
          d="M13.5 0H4.5C1.98 0 0 1.98 0 4.5V13.5C0 16.02 1.98 18 4.5 18H13.5C16.02 18 18 16.02 18 13.5V4.5C18 1.98 16.02 0 13.5 0ZM16.2 13.5C16.2 15.03 15.03 16.2 13.5 16.2H4.5C2.97 16.2 1.8 15.03 1.8 13.5V4.5C1.8 2.97 2.97 1.8 4.5 1.8H13.5C15.03 1.8 16.2 2.97 16.2 4.5V13.5Z"
          fill="white"
        />
        <path
          d="M9 4.5C6.48 4.5 4.5 6.48 4.5 9C4.5 11.52 6.48 13.5 9 13.5C11.52 13.5 13.5 11.52 13.5 9C13.5 6.48 11.52 4.5 9 4.5ZM9 11.7C7.47 11.7 6.3 10.53 6.3 9C6.3 7.47 7.47 6.3 9 6.3C10.53 6.3 11.7 7.47 11.7 9C11.7 10.53 10.53 11.7 9 11.7Z"
          fill="white"
        />
        <path
          d="M13.4996 5.4001C13.9967 5.4001 14.3996 4.99715 14.3996 4.5001C14.3996 4.00304 13.9967 3.6001 13.4996 3.6001C13.0026 3.6001 12.5996 4.00304 12.5996 4.5001C12.5996 4.99715 13.0026 5.4001 13.4996 5.4001Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_156_684">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function YoutubeWhiteIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_156_689)">
        <path
          d="M17.602 4.44676L17.6245 4.59376C17.5219 4.21969 17.3256 3.87794 17.0542 3.60079C16.7828 3.32364 16.4453 3.12022 16.0735 3.00976L16.0577 3.00601C14.6545 2.62501 9.00772 2.62501 9.00772 2.62501C9.00772 2.62501 3.37522 2.61751 1.95772 3.00601C1.58525 3.1138 1.24638 3.31469 0.973047 3.58972C0.699719 3.86476 0.500945 4.20488 0.395471 4.57801L0.391721 4.59376C-0.132529 7.33201 -0.136279 10.6223 0.414971 13.554L0.391721 13.4055C0.494334 13.7796 0.690595 14.1213 0.961973 14.3985C1.23335 14.6756 1.57089 14.879 1.94272 14.9895L1.95847 14.9933C3.36022 15.375 9.00847 15.375 9.00847 15.375C9.00847 15.375 14.6402 15.375 16.0585 14.9933C16.4311 14.8856 16.7702 14.6848 17.0436 14.4098C17.3171 14.1347 17.516 13.7945 17.6215 13.4213L17.6252 13.4055C17.8637 12.132 18.0002 10.6665 18.0002 9.16951L17.9995 9.00376L18.0002 8.83126C18.0002 7.33351 17.8637 5.86801 17.602 4.44676ZM7.20622 11.7383V6.26851L11.9057 9.00751L7.20622 11.7383Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_156_689">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function UnBookmarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M13.2857 14.3333L9 12.3956L4.71429 14.3333V2.77778H13.2857M13.2857 1H4.71429C4.25963 1 3.82359 1.1873 3.5021 1.5207C3.18061 1.8541 3 2.30628 3 2.77778V17L9 14.3333L15 17V2.77778C15 1.79111 14.2286 1 13.2857 1Z"
        fill="#fff"
      />
    </svg>
  );
}
export function BookmarkIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3854 5.70221H5.95393C5.91634 5.7021 5.87911 5.70942 5.84436 5.72375C5.80962 5.73808 5.77805 5.75914 5.75147 5.78571C5.7249 5.81229 5.70384 5.84386 5.68951 5.8786C5.67518 5.91335 5.66786 5.95059 5.66797 5.98817V18.7857C5.66994 19.0281 5.74381 19.2644 5.88023 19.4648C6.01665 19.6651 6.20948 19.8205 6.43427 19.9111C6.65906 20.0018 6.90571 20.0236 7.14295 19.974C7.38019 19.9243 7.59735 19.8054 7.76691 19.6321L10.9239 16.3436L14.0783 19.6321C14.2482 19.8052 14.4655 19.924 14.7029 19.9736C14.9403 20.0231 15.187 20.0012 15.4119 19.9107C15.6369 19.8201 15.8299 19.6649 15.9667 19.4647C16.1035 19.2644 16.1778 19.0282 16.1804 18.7857V10.0494C13.7257 10.5641 11.545 8.06824 12.3857 5.70278L12.3854 5.70221Z"
        fill="white"
      />
      <path
        d="M15.5536 3.99219C11.8673 4.14575 11.8939 9.39796 15.5536 9.53722C16.284 9.52981 16.982 9.23443 17.4959 8.71529C18.0098 8.19615 18.2981 7.49518 18.2981 6.76471C18.2981 6.03423 18.0098 5.33326 17.4959 4.81412C16.982 4.29498 16.284 3.99961 15.5536 3.99219ZM16.8976 7.05195H15.8395V8.10743C15.8395 8.18327 15.8094 8.256 15.7558 8.30963C15.7022 8.36326 15.6294 8.39339 15.5536 8.39339C15.4777 8.39339 15.405 8.36326 15.3514 8.30963C15.2978 8.256 15.2676 8.18327 15.2676 8.10743V7.05195H14.2124C14.1366 7.05195 14.0639 7.02182 14.0102 6.9682C13.9566 6.91457 13.9265 6.84183 13.9265 6.76599C13.9265 6.69015 13.9566 6.61742 14.0102 6.56379C14.0639 6.51016 14.1366 6.48003 14.2124 6.48003H15.2676V5.42198C15.2676 5.34614 15.2978 5.27341 15.3514 5.21978C15.405 5.16615 15.4777 5.13602 15.5536 5.13602C15.6294 5.13602 15.7022 5.16615 15.7558 5.21978C15.8094 5.27341 15.8395 5.34614 15.8395 5.42198V6.48003H16.8976C16.9734 6.48003 17.0462 6.51016 17.0998 6.56379C17.1534 6.61742 17.1836 6.69015 17.1836 6.76599C17.1836 6.84183 17.1534 6.91457 17.0998 6.9682C17.0462 7.02182 16.9734 7.05195 16.8976 7.05195Z"
        fill="white"
      />
    </svg>
  );
}
