import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  del,
  get,
  thunkHandler,
} from "../../helpers/base";

const initialState = {
  newsLetterData: [],
  newsLetterCount: 0,
  newsLetterLoader: false,
};

export const getNewsLetter = createAsyncThunk(
  "admin-services/dashboard/get-all-newsletter-subscribers",
  async (data, thunkAPI) => {
    https: return await thunkHandler(
      get("admin-services/dashboard/get-all-newsletter-subscribers", data),
      thunkAPI
    );
  }
);
export const deleteNewsLetter = createAsyncThunk(
  `admin-services/dashboard/delete-newsletter-subscriber/id`,
  async (data, thunkAPI) => {
    return await thunkHandler(
      del(
        `admin-services/dashboard/delete-newsletter-subscriber/${data?.id}`
      ),
      thunkAPI
    );
  }
);

const NewsLetterSlice = createSlice({
  name: "NewsLetter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewsLetter.pending, (state, action) => {
        state.newsLetterLoader = true;
      })
      .addCase(getNewsLetter.fulfilled, (state, action) => {
        state.newsLetterData = action?.payload?.payload?.user;
        state.newsLetterCount = action?.payload?.payload?.counts;
        state.newsLetterLoader = false;
      })
      .addCase(getNewsLetter.rejected, (state, action) => {
        state.newsLetterData = {};
        state.newsLetterLoader = false;
      })
     
  },
});

export const {} = NewsLetterSlice.actions;

export default NewsLetterSlice.reducer;
