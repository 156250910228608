import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, Input, Label, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classnames from "classnames";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { registerPlugin } from "react-filepond";
import Select from "react-select";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { BUTTON_LIST, CREATE_TOOL_VALUE, WEBSITE_URL, multiSelectStyle, singleSelectStyle } from "../../helpers/constnt";
import { toast } from "react-toastify";
import { createBlog, getBlogs, getToolsSubCategory, updateBlog } from "../../slices/blogs";
import CommonModal from "../../Components/Common/CommonModal";
import MultiValueInput from "../../Components/Common/MultiValueInput";
import Loader from "../../Components/Common/Loader";
import BlogPreviewModal from "../../Components/Common/BlogPreviewModal/BlogPreviewModal";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateBlog = (props) => {
  const [enteredValues, setEnteredValues] = useState([]);
  const [inputValue, setInputValue] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { toolLoader, toolData } = useSelector((state) => state.Tool);
  const [toolSubCategoryData, setToolSubCategoryData] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState(1);
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { slugId } = useParams();
  const { pathname } = useLocation();
  document.title = `${slugId ? "Update" : "Create"}  Blog | WriterTools`;

  useEffect(() => {
    const params = {};
    params.page = 1;
    params.limit = 130;
    params.status = "active";
    dispatch(
      getToolsSubCategory({
        ...params,
      })
    )
      .then((res) => {
        if (res?.payload?.success) {
          const data = res?.payload?.payload?.blog_category?.map((item) => {
            return {
              value: item?._id,
              label: item?.title,
            };
          });
          setToolSubCategoryData(data);
        }
      })
      .catch(() => {});
    setErrors({});
    if (slugId) {
      handleGetToolData();
    } else {
      setInputValue(CREATE_TOOL_VALUE);
      setErrors({});
      setEnteredValues([]);
    }
  }, [pathname, slugId]);
  useEffect(() => {
    if (slugId) {
      setEnteredValues(inputValue?.keyWords?.flatMap((keyword) => keyword.split(",")));
    }
  }, [inputValue?.keyWords]);
  const handleAddEnteredValue = (newValue) => {
    setEnteredValues([...enteredValues, newValue]);
  };

  const handleRemoveEnteredValue = (valueToRemove) => {
    const filteredValues = enteredValues.filter((value) => value !== valueToRemove);
    setEnteredValues(filteredValues);
  };

  const handleGetToolData = () => {
    const params = {};
    params.id = slugId;
    params.page = 1;
    if (!slugId) {
      params.createdBy = "Admin";
    }
    params.limit = 10;
    if (slugId) {
      dispatch(
        getBlogs({
          ...params,
        })
      ).then((res) => {
        const data = res?.payload?.payload?.blog_category;
        if (res?.payload?.success) {
          const val = {
            updateId: data?._id,
            blogCoverImage: data?.coverPhoto || "",
            title: data?.title || "",
            coverPhotoAltTag: data?.coverPhotoAltTag || "",
            metaTitle: data?.metaTitle || "",
            metaDescription: data?.metaDescription || "",
            websiteUrl: data?.websiteLink || "",
            slugURL: data?.slugId,
            category: data?.blogCategoryId,
            keywords: data?.keyWords,
            description: data?.description || "",
            shortDescription: data?.sortDescription || "",
            YouTubeChannelLink: data?.youtubeLink || "",
            InstagramChannelLink: data?.instagramLink || "",
            FacebookChannelLink: data?.facebookLink || "",
            TwitterChannelLink: data?.twitterLink || "",
            LinkdinChannelLink: data?.linkedinLink || "",
            status: data?.status || "Approved",
          };
          setInputValue(val);
          setEnteredValues(data?.keyWords || []);
          setFaqData(data?.FAQs)
        }
      });
    }
  };
  const handleEditorChange = (val) => {
    if (val == "<p><br></p>") {
      setInputValue((prevState) => ({
        ...prevState,
        description: "",
      }));
    } else {
      setInputValue((prevState) => ({
        ...prevState,
        description: val,
      }));
    }
  };

  const validateField = (fieldName, value) => {
    let error = null;

    switch (fieldName) {
      case "title":
        if (!value) error = " Blog title is required";
        break;
      case "coverPhotoAltTag":
        if (!value) error = " coverPhotoAltTag is required ";
        break;
      case "metaTitle":
        if (!value) error = " metaTitle is required";
        break;
      case "metaDescription":
        if (!value) error = "metaDescription is required";
        break;
      case "blogCoverImage":
        if (!value) error = "Blog Cover image is required";
        break;
      case "websiteUrl":
        if (value && !WEBSITE_URL?.test(value)) error = "Website URL is not valid";
        break;
      case "shortDescription":
        if (!value) error = " Short Description is required";
        break;
      case "YouTubeChannelLink":
      case "InstagramChannelLink":
      case "FacebookChannelLink":
      case "TwitterChannelLink":
      case "LinkdinChannelLink":
        if (value && !WEBSITE_URL?.test(value)) error = "URL is not valid";
        break;

      case "category":
        if (!value) error = "Category is required";
        break;
      case "description":
        if (!value) error = "Description is required";
        break;
      default:
        break;
    }

    return error; // Return the error, if any
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    const fieldsToValidate = [
      "title",
      "blogCoverImage",
      "websiteUrl",
      "category",
      "description",
      "YouTubeChannelLink",
      "InstagramChannelLink",
      "FacebookChannelLink",
      "TwitterChannelLink",
      "LinkdinChannelLink",
      "shortDescription",
      "coverPhotoAltTag",
      "metaTitle",
      "metaDescription",
    ];

    fieldsToValidate?.forEach((field) => {
      const error = validateField(field, inputValue[field]);
      if (error) {
        newErrors[field] = error;
      }
    });
    setErrors(newErrors);
    const isValid = Object.keys(newErrors)?.length === 0;

    if (isValid) {
      if (pathname?.startsWith("/update-user-tool") && inputValue?.status?.toLowerCase() == "rejected" && !inputValue?.reason) {
        setIsOpenModal(true);
        return;
      }
      const formdata = new FormData();

      formdata.append("title", inputValue?.title);
      formdata.append("coverPhotoAltTag", inputValue?.coverPhotoAltTag);
      formdata.append("metaTitle", inputValue?.metaTitle);
      formdata.append("metaDescription", inputValue?.metaDescription);
      formdata.append("description", inputValue?.description);
      formdata.append("sortDescription", inputValue?.shortDescription);
      if (inputValue?.blogCoverImageFile) {
        formdata.append("coverPhoto", inputValue?.blogCoverImageFile);
      }

      if (inputValue?.slugURL) {
        formdata.append("slugId", inputValue?.slugURL);
      }

      if (enteredValues && enteredValues?.length > 0) {
        enteredValues.forEach((keyWord, index) => {
          formdata.append(`keyWords[${index}]`, keyWord);
        });
      }
      if (faqData && faqData?.length > 0) {
        faqData.forEach((faq, index) => {
          formdata.append(`FAQs[${index}][question]`, faq.question);
          formdata.append(`FAQs[${index}][answer]`, faq.answer);
        });
      }
      if (inputValue?.websiteUrl) {
        formdata.append("websiteLink", inputValue?.websiteUrl);
      }
      if (inputValue.InstagramChannelLink) {
        formdata.append("instagramLink", inputValue.InstagramChannelLink);
      }
      if (inputValue.LinkdinChannelLink) {
        formdata.append("linkedinLink", inputValue.LinkdinChannelLink);
      }
      if (inputValue.FacebookChannelLink) {
        formdata.append("facebookLink", inputValue.FacebookChannelLink);
      }
      if (inputValue.TwitterChannelLink) {
        formdata.append("twitterLink", inputValue.TwitterChannelLink);
      }
      if (inputValue.YouTubeChannelLink) {
        formdata.append("youtubeLink", inputValue.YouTubeChannelLink);
      }
      if (inputValue?.reason) {
        formdata.append("reject_reason", inputValue?.reason);
      }

      Object.keys(inputValue)?.forEach((key) => {
        if (key === "category") {
          inputValue[key]?.map((item) => {
            return formdata.append("blogCategoryId[]", [item]);
          });
        }
      });

      if (!slugId) {
        dispatch(createBlog({ body: formdata }))
          .then((res) => {
            if (res?.payload?.success) {
              toast.success("Your blog has been submitted Successfully");
              navigate("/admin-blogs");
              setEnteredValues([]);
              setInputValue(CREATE_TOOL_VALUE);
              setErrors({});
            } else {
              toast.error(res?.payload?.data?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message);
          });
      } else {
        dispatch(updateBlog({ body: formdata, id: inputValue?.updateId }))
          .then((res) => {
            if (res?.payload?.success) {
              toast.success("Blogs updated successfully");
              if (pathname?.startsWith("/update-admin-blogs")) {
                navigate("/admin-blogs");
              } else {
                navigate("/admin-blogs");
              }
            } else {
              toast.error(res?.payload?.data?.message);
            }
          })
          .catch((err) => {
            console.log("🚀 ~ handleSubmit ~ err:", err);
          });
      }
    }
  };
  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setInputValue({
          ...inputValue,
          [event?.target?.name]: reader.result,
          [`${event?.target?.name}File`]: file,
        });
        const error = validateField(event?.target?.name, reader.result);
        setErrors({
          ...errors,
          [event?.target?.name]: error,
        });
      };
      reader.readAsDataURL(file);
    }
    event.target.value = "";
  };
  const handleImageDelete = (name) => {
    setInputValue({
      ...inputValue,
      [name]: "",
      [`${event?.target?.name}File`]: "",
    });

    const error = validateField(name, "");
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e?.target;

    setInputValue({
      ...inputValue,
      [name]: value?.trimStart(),
    });
    const error = validateField(name, value?.trimStart());

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const [faqData, setFaqData] = useState([]);

  const handleAddFeature = () => {
    for (let faq of faqData) {
      if (faq.question === "" || faq.answer === "") {
        setErrors({
          ...errors,
          FAQs: "Please fill out all fields before adding a new Faqs.",
        });
        return;
      } else {
        setErrors({
          ...errors,
          FAQs: "",
        });
      }
    }

    const obj = { question: "", answer: "" };
    setFaqData([...faqData, obj]);
  };

  const handleDeleteFeature = (index) => {
    const data = faqData.filter((_, i) => i !== index);
    setFaqData(data);
    setErrors({
      ...errors,
      FAQs: "",
    });
  };

  const handleInputChange = (index, value, field) => {
    const val = value?.trimStart();
    const updatedFeatures = faqData?.map((feature, i) => (i === index ? { ...feature, [field]: val } : feature));
    setFaqData(updatedFeatures);
  };

  return (
    <div className="page-content">
      {toolLoader && <Loader />}
      <Container fluid>
        <BreadCrumb title={`${slugId ? "Update" : "Create"} Blog`} pageTitle="Tool" />
        <div className="text-end mb-3">
          <Button type="submit" className="btn btn-success w-sm me-2" onClick={(e) => (toolLoader ? {} : handleSubmit(e))} disabled={toolLoader}>
            Submit
          </Button>
          <Button type="submit" className="btn  w-sm me-2" color="primary" onClick={() => setPreviewModal(true)} disabled={toolLoader}>
            Preview
          </Button>
          {slugId && (
            <Button
              type="button"
              className="btn btn-primary w-sm"
              onClick={() => {
                pathname?.startsWith("/update-admin-blogs") ? navigate("/admin-blogs") : navigate("/admin-blogs");
              }}
            >
              Cancel
            </Button>
          )}
        </div>
        <Row>
          <Col lg={8}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Blog Gallery</h5>
              </CardHeader>
              <CardBody>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <h5 className="fs-14 mb-4">
                        Blog Cover Image <span className="text-danger">*</span>
                        {errors?.blogCoverImage && (
                          <span
                            className="text-danger"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {errors?.blogCoverImage}
                          </span>
                        )}
                      </h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            {!inputValue?.blogCoverImage ? (
                              <label htmlFor="tool-website-image-input" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Select Image">
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                            ) : (
                              <div className="avatar-xs" onClick={() => handleImageDelete("blogCoverImage")}>
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-delete-bin-6-line"></i>
                                </div>
                              </div>
                            )}
                            <input
                              className="form-control d-none"
                              defaultValue=""
                              id="tool-website-image-input"
                              type="file"
                              name="blogCoverImage"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => handleImageChange(e)}
                            />
                          </div>
                          <div className="avatar-lg" style={{ width: "200px" }}>
                            <div className="avatar-title bg-light rounded">
                              {inputValue?.blogCoverImage && <img src={inputValue?.blogCoverImage} id="tool-website-img" alt="" className="avatar-md h-100 w-100 rounded" />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="mb-3 row">
                  <div className="col-12 col-sm-6">
                    <Label className="form-label" htmlFor="tool-name">
                      Blog Title <span className="text-danger">*</span>{" "}
                      {errors?.title && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.title}
                        </span>
                      )}
                    </Label>
                    <Input type="text" className="form-control" id="tool-name" placeholder="Enter blog title" name="title" value={inputValue?.title || ""} onChange={handleOnChange} />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-md-0">
                    <Label className="form-label" htmlFor="website-url">
                      Website URL
                      {errors?.websiteUrl && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.websiteUrl}
                        </span>
                      )}
                    </Label>
                    <div className="input-container">
                      <Input type="text" className="form-control" id="website-url" placeholder="Enter website url" name="websiteUrl" value={inputValue?.websiteUrl} onChange={handleOnChange} />
                      {inputValue?.websiteUrl && (
                        <>
                          <span className="vertical-line"></span>
                          <a href={inputValue?.websiteUrl} target="_blank" rel="noopener noreferrer" className="icon-link">
                            <i className="ri-links-line"></i>{" "}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-12 col-sm-6">
                    <Label className="form-label" htmlFor="category">
                      Blog Category <span className="text-danger">*</span>{" "}
                      {errors?.category && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.category}
                        </span>
                      )}
                    </Label>
                    <Select
                      id="category"
                      value={inputValue?.category ? toolSubCategoryData?.filter((item) => inputValue?.category.includes(item?.value)) : []}
                      isMulti
                      onChange={(selectedOptions) => {
                        setInputValue({
                          ...inputValue,
                          category: selectedOptions ? selectedOptions.map((option) => option.value) : [],
                        });
                        setErrors({
                          ...errors,
                          category: selectedOptions?.length ? "" : "Category is required",
                        });
                      }}
                      placeholder="Select category"
                      options={toolSubCategoryData}
                      styles={multiSelectStyle}
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-md-0">
                    <Label className="form-label" htmlFor="slug-url">
                      Slug URL
                    </Label>
                    <div className="input-container">
                      <Input type="text" className="form-control" id="slug-url" placeholder="Enter Slug URL" name="slugURL" value={inputValue?.slugURL} onChange={handleOnChange} />
                      {inputValue?.slugURL && (
                        <>
                          <span className="vertical-line"></span>
                          <a href={inputValue?.slugURL} target="_blank" rel="noopener noreferrer" className="icon-link">
                            <i className="ri-links-line"></i>{" "}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-12 col-sm-6">
                    <Label className="form-label" htmlFor="short-description">
                      Short Description <span className="text-danger">*</span>{" "}
                      {errors?.shortDescription && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.shortDescription}
                        </span>
                      )}
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="short-description"
                      placeholder="Enter short description"
                      name="shortDescription"
                      value={inputValue?.shortDescription || ""}
                      onChange={handleOnChange}
                      rows={3}
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-md-0">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="meta-keywords-input">
                        Keywords
                      </Label>
                      <Label htmlFor="choices-text-remove-button" className="form-label text-muted"></Label>
                      <MultiValueInput
                        handleAddEnteredValue={handleAddEnteredValue}
                        handleRemoveEnteredValue={handleRemoveEnteredValue}
                        enteredValues={enteredValues}
                        setEnteredValues={setEnteredValues}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-12 col-sm-6">
                    <Label className="form-label" htmlFor="metaTitle">
                      Meta Title <span className="text-danger">*</span>{" "}
                      {errors?.metaTitle && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.metaTitle}
                        </span>
                      )}
                    </Label>
                    <Input type="text" className="form-control" id="metaTitle" placeholder="Enter meta title" name="metaTitle" value={inputValue?.metaTitle || ""} onChange={handleOnChange} />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-md-0">
                    <Label className="form-label" htmlFor="metaDescription">
                      Meta Description<span className="text-danger">*</span>
                      {errors?.metaDescription && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.metaDescription}
                        </span>
                      )}
                    </Label>
                    <div className="input-container">
                      <Input
                        type="text"
                        className="form-control"
                        id="metaDescription"
                        placeholder="Enter Meta Description"
                        name="metaDescription"
                        value={inputValue?.metaDescription}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-12 col-sm-6">
                    <Label className="form-label" htmlFor="coverPhotoAltTag">
                      Cover Photo Alt Tag <span className="text-danger">*</span>{" "}
                      {errors?.coverPhotoAltTag && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors?.coverPhotoAltTag}
                        </span>
                      )}
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="coverPhotoAltTag"
                      placeholder="Enter cover photo alt tag"
                      name="coverPhotoAltTag"
                      value={inputValue?.coverPhotoAltTag || ""}
                      onChange={handleOnChange}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="card-title mb-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">
                    FAQs{" "}
                    {errors?.FAQs && (
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {errors?.FAQs}
                      </span>
                    )}
                  </h5>
                  <Button type="submit" className="btn btn-success w-sm me-2" onClick={handleAddFeature}>
                    + Add
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className="container-fluid m-0 p-0">
                  {faqData?.length ? (
                    <div className="row ">
                      {faqData?.map((feature, index) => (
                        <div className="col col-md-6 mb-3" key={index}>
                          <div className="border p-3">
                            <div className="d-flex justify-content-between pe-0">
                              <div className="avatar-xs">
                                <span style={{backgroundColor:"#F3F8FF",color:"black"}} className="avatar-title rounded cursor-pointer" >
                                {index+1}
                                </span>
                              </div>
                              <div className="avatar-xs">
                                <span className="avatar-title rounded fs-5 bg-soft-danger cursor-pointer" onClick={() => handleDeleteFeature(index)}>
                                  <i className="ri-delete-bin-fill align-bottom text-danger"></i>
                                </span>
                              </div>
                            </div>
                            <div className="mt-2 mb-3">
                              <Label className="form-label" htmlFor={`Faqs-question-${index}`}>
                                Faqs Question 
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id={`Faqs-question-${index}`}
                                placeholder="Enter Faqs question"
                                value={feature.question}
                                onChange={(e) => handleInputChange(index, e.target.value, "question")}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor={`feature-description-${index}`}>
                                Faqs Answer {index+1}
                              </Label>
                              <textarea
                                type="text"
                                className="form-control"
                                rows={5}
                                id={`faqs-answer-${index}`}
                                placeholder="Enter Faqs Answer"
                                value={feature.answer}
                                onChange={(e) => handleInputChange(index, e.target.value, "answer")}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-muted">
                      <p>No features have been added yet. Click the "+ Add" button to get started.</p>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            {pathname?.startsWith("/update-user-tool") && (
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Status</h5>
                </CardHeader>
                <CardBody>
                  <div className="row">
                    {["Pending", "Approved", "Rejected"].map((statusOption) => (
                      <div key={statusOption} className="col-4 p-0 ps-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={statusOption}
                          checked={inputValue?.status?.toLowerCase() === statusOption?.toLowerCase()}
                          onChange={(e) => {
                            setInputValue(() => ({
                              ...inputValue,
                              status: e?.target?.id,
                            }));
                          }}
                        />
                        <label className="form-check-label ms-2" htmlFor={statusOption}>
                          {statusOption}
                        </label>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            )}

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Social media</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <div>
                    <Label className="form-label" htmlFor="YouTube-channelLink">
                      YouTube Social Media Link{" "}
                      {errors?.YouTubeChannelLink && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {errors?.YouTubeChannelLink}
                        </span>
                      )}
                    </Label>
                  </div>
                  <div className="input-container">
                    <Input
                      type="text"
                      className="form-control"
                      id="YouTube-channelLink"
                      placeholder="YouTube Social Media Link"
                      name="YouTubeChannelLink"
                      value={inputValue?.YouTubeChannelLink}
                      onChange={handleOnChange}
                    />
                    {slugId && inputValue?.YouTubeChannelLink && (
                      <>
                        <span className="vertical-line"></span>

                        <a href={inputValue?.YouTubeChannelLink} target="_blank" rel="noopener noreferrer" className="icon-link">
                          <i class="ri-links-line"></i>{" "}
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  {" "}
                  <div>
                    <Label className="form-label" htmlFor="InstagramChannelLink">
                      Instagram Social Media Link{" "}
                      {errors?.InstagramChannelLink && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {errors?.InstagramChannelLink}
                        </span>
                      )}
                    </Label>
                  </div>
                  <div className="input-container">
                    <Input
                      type="text"
                      className="form-control"
                      id="InstagramChannelLink"
                      placeholder="Instagram Social Media Link"
                      name="InstagramChannelLink"
                      value={inputValue?.InstagramChannelLink}
                      onChange={handleOnChange}
                    />
                    {slugId && inputValue?.InstagramChannelLink && (
                      <>
                        <span className="vertical-line"></span>

                        <a href={inputValue?.InstagramChannelLink} target="_blank" rel="noopener noreferrer" className="icon-link">
                          <i class="ri-links-line"></i>{" "}
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <div>
                    <Label className="form-label" htmlFor="FacebookChannelLink">
                      Facebook Social Media Link{" "}
                      {errors?.FacebookChannelLink && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {errors?.FacebookChannelLink}
                        </span>
                      )}
                    </Label>
                  </div>
                  <div className="input-container">
                    <Input
                      type="text"
                      className="form-control"
                      id="FacebookChannelLink"
                      placeholder="Facebook Social Media Link"
                      name="FacebookChannelLink"
                      value={inputValue?.FacebookChannelLink}
                      onChange={handleOnChange}
                    />{" "}
                    {slugId && inputValue?.FacebookChannelLink && (
                      <>
                        <span className="vertical-line"></span>

                        <a href={inputValue?.FacebookChannelLink} target="_blank" rel="noopener noreferrer" className="icon-link">
                          <i class="ri-links-line"></i>{" "}
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  {" "}
                  <div>
                    <Label className="form-label" htmlFor="TwitterChannelLink">
                      Twitter Social Media Link{" "}
                      {errors?.TwitterChannelLink && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {errors?.TwitterChannelLink}
                        </span>
                      )}
                    </Label>
                  </div>
                  <div className="input-container">
                    <Input
                      type="text"
                      className="form-control"
                      id="TwitterChannelLink"
                      placeholder="Twitter Social Media Link"
                      name="TwitterChannelLink"
                      value={inputValue?.TwitterChannelLink}
                      onChange={handleOnChange}
                    />
                    {slugId && inputValue?.TwitterChannelLink && (
                      <>
                        <span className="vertical-line"></span>

                        <a href={inputValue?.TwitterChannelLink} target="_blank" rel="noopener noreferrer" className="icon-link">
                          <i class="ri-links-line"></i>{" "}
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <div>
                    <Label className="form-label" htmlFor="LinkdinChannelLink">
                      Linkdin Social Media Link{" "}
                      {errors?.LinkdinChannelLink && (
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {errors?.LinkdinChannelLink}
                        </span>
                      )}
                    </Label>
                  </div>
                  <div className="input-container">
                    <Input
                      type="text"
                      className="form-control"
                      id="LinkdinChannelLink"
                      placeholder="Linkdin Social Media Link"
                      name="LinkdinChannelLink"
                      value={inputValue?.LinkdinChannelLink}
                      onChange={handleOnChange}
                    />
                    {slugId && inputValue?.LinkdinChannelLink && (
                      <>
                        <span className="vertical-line"></span>

                        <a href={inputValue?.LinkdinChannelLink} target="_blank" rel="noopener noreferrer" className="icon-link">
                          <i class="ri-links-line"></i>{" "}
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="mb-2">
                <Label>
                  Blog Description <span className="text-danger">*</span>{" "}
                  {errors?.description && (
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {errors?.description}
                    </span>
                  )}
                </Label>

                <div className="editor-container" style={{ height: "400px" }}>
                  <SunEditor
                    setOptions={{
                      buttonList: BUTTON_LIST,
                      minHeight: "300px",
                      maxHeight: "600px",
                    }}
                    onChange={handleEditorChange}
                    setContents={inputValue?.description}
                    placeholder="Write a detailed description of your Blog"
                    name="description"
                  />
                </div>
              </div>{" "}
            </CardBody>
          </Card>
        </Row>
      </Container>
      <CommonModal
        body={
          <div className="mt-2 text-start">
            <Label>Reason</Label>
            <textarea
              className="form-control"
              id="meta-description-input"
              placeholder="Enter Reason..."
              name="meta_description"
              rows="5"
              value={inputValue?.reason}
              onChange={(e) => {
                setInputValue({
                  ...inputValue,
                  reason: e?.target?.value?.trimStart(),
                });
              }}
            ></textarea>
          </div>
        }
        isOpen={isOpenModal}
        onSubmit={() => {
          setIsOpenModal(false);
        }}
        onCloseClick={() => {
          setIsOpenModal(false);
          setInputValue({
            ...inputValue,
            reason: "",
          });
        }}
        isDisabled={!inputValue?.reason}
        buttonText="Submit"
        header="Status"
      />
      <Modal isOpen={previewModal} size="xl" scrollable={true} centered id="exampleModal">
        <ModalHeader
          toggle={() => {
            setPreviewModal(false);
          }}
        >
          Blog Preview
        </ModalHeader>
        <ModalBody>
          <BlogPreviewModal singleBlog={inputValue} coverPhotoPreview={inputValue?.blogCoverImage} keyWords={enteredValues} isWriteBlog={true} />
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setPreviewModal(false);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateBlog;
