import React from "react";

export default function InstagramIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0H5C2.2 0 0 2.2 0 5V15C0 17.8 2.2 20 5 20H15C17.8 20 20 17.8 20 15V5C20 2.2 17.8 0 15 0ZM18 15C18 16.7 16.7 18 15 18H5C3.3 18 2 16.7 2 15V5C2 3.3 3.3 2 5 2H15C16.7 2 18 3.3 18 5V15Z"
        fill="black"
      />
      <path
        d="M10 5C7.2 5 5 7.2 5 10C5 12.8 7.2 15 10 15C12.8 15 15 12.8 15 10C15 7.2 12.8 5 10 5ZM10 13C8.3 13 7 11.7 7 10C7 8.3 8.3 7 10 7C11.7 7 13 8.3 13 10C13 11.7 11.7 13 10 13Z"
        fill="black"
      />
      <path
        d="M14.9996 6.00011C15.5519 6.00011 15.9996 5.55239 15.9996 5.00011C15.9996 4.44782 15.5519 4.00011 14.9996 4.00011C14.4473 4.00011 13.9996 4.44782 13.9996 5.00011C13.9996 5.55239 14.4473 6.00011 14.9996 6.00011Z"
        fill="black"
      />
    </svg>
  );
}
