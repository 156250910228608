import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  del,
  get,
  post,
  postFormData,
  put,
  putFormData,
  thunkHandler,
} from "../../helpers/base";

const initialState = {
  AdminData: [],
  AdminCount: 0,
  AdminLoader: false,
};

export const getAdminDetails = createAsyncThunk(
  "user-services/user/get-profile",
  async (data, thunkAPI) => {
    https: return await thunkHandler(
      get("user-services/user/get-profile"),
      thunkAPI
    );
  }
);

export const updateAdminDetails = createAsyncThunk(
  "user-services/user/update-profile",
  async ({ body, id }, thunkAPI) => {
    return await thunkHandler(
      putFormData(`user-services/user/update-profile`, body),
      thunkAPI
    );
  }
);
const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminDetails.pending, (state, action) => {
        state.AdminLoader = true;
      })
      .addCase(getAdminDetails.fulfilled, (state, action) => {
        state.AdminData = action?.payload?.payload;
        state.AdminCount = action?.payload?.payload?.counts;
        state.AdminLoader = false;
      })
      .addCase(getAdminDetails.rejected, (state, action) => {
        state.AdminData = {};
        state.AdminLoader = false;
      })
      .addCase(updateAdminDetails.pending, (state, action) => {
        state.AdminLoader = true;
      })
      .addCase(updateAdminDetails.fulfilled, (state, action) => {
        state.AdminLoader = false;
      })
      .addCase(updateAdminDetails.rejected, (state, action) => {
        state.AdminLoader = false;
      });
  },
});

export const {} = ProfileSlice.actions;

export default ProfileSlice.reducer;
