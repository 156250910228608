export default function YouTubeIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7564_142)">
        <path
          d="M23.6654 6.28245C23.5394 5.23155 22.5491 4.22384 21.5129 4.08884C15.1955 3.30372 8.80531 3.30372 2.48798 4.08884C1.45091 4.22354 0.460541 5.23155 0.334562 6.28245C-0.111521 10.134 -0.111521 13.8684 0.334562 17.719C0.460541 18.7699 1.45091 19.7786 2.48798 19.9126C8.80531 20.6977 15.1955 20.6977 21.5129 19.9126C22.5491 19.7788 23.5394 18.7699 23.6654 17.719C24.1116 13.8687 24.1116 10.134 23.6654 6.28245ZM9.99985 16.0015V8.00122L16 12.0015L9.99985 16.0015Z"
          fill="#292D36"
        />
      </g>
      <defs>
        <clipPath id="clip0_7564_142">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
