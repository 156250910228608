import { marked } from "marked";
import { useEffect, useState } from "react";
import "./BlogPreviewModal.scss";
import {
  FacebookWhiteIcon,
  InstagramWhiteIcon,
  LinkedinWhiteIcon,
  TwitterWhiteIcon,
  YoutubeWhiteIcon,
} from "./../../../../assets/icons/Icons";
import FacebookIcon from "./../../../../assets/icons/facebookIcon";
import TwitterIcon from "./../../../../assets/icons/twitterIcon";
import LinkdinIcon from "./../../../../assets/icons/linkdinIcon";
import InstagramIcon from "./../../../../assets/icons/Instagram";
import YouTubeIcon from "./../../../../assets/icons/YoutubeIcon";

import ProfileImage from "./../../../../assets/images/profile.png";
import ProfileLg from "./../../../../assets/images/userWhite.png";
import { getAdminDetails } from "../../../../slices/profile";
import { useDispatch } from "react-redux";

export default function BlogPreviewModal({
  singleBlog,
  keyWords,
  isWriteBlog,
}) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const handleLinkClick = (Link) => {
    window.open(Link, "_blank");
  };
  useEffect(() => {
    if (isWriteBlog) {
      dispatch(getAdminDetails())
        .then((res) => {
          if (res?.payload?.success === true) {
            const val = {
              userName: res?.payload?.payload?.[0]?.userName,
              profileImage: res?.payload?.payload?.[0]?.profileImage,
              email: res?.payload?.payload?.[0]?.email,
              shortBio: res?.payload?.payload?.[0]?.shortBio,
              productName: res?.payload?.payload?.[0]?.productName,
              productURL: res?.payload?.payload?.[0]?.productURL,
              productCategory: res?.payload?.payload?.[0]?.productCategory,
              intrestedCategories:
                res?.payload?.payload?.[0]?.interested_categories,
              facebookLink: res?.payload?.payload?.[0]?.facebookLink,
              linkedinLink: res?.payload?.payload?.[0]?.linkedinLink,
              instagramLink: res?.payload?.payload?.[0]?.instagramLink,
              twitterLink: res?.payload?.payload?.[0]?.twitterLink,
              youtubeLink: res?.payload?.payload?.[0]?.youtubeLink,
            };
            setUserData(val);
          }
        })
        .catch((error) => {
          toast.error("Failed to Fetch Admin Details");
        });
    }
  }, []);
  useEffect(() => {
    if (!singleBlog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [singleBlog]);
  return (
    <>
      {!singleBlog ? (
        <div style={{ marginTop: "25px" }}>Loading</div>
      ) : (
        <div className="blogInsideInformation">
          <div className="skipText">{/*  */}</div>
          <div style={{ marginTop: "20px" }} className="title">
            <h2>{singleBlog?.title}</h2>
          </div>
          <div className="personalInformation">
            <div className="profileInformation">
              <div className="img">
                <img
                  src={
                    isWriteBlog
                      ? userData?.profileImage ||
                        singleBlog?.Users?.profileImage ||
                        ProfileImage
                      : singleBlog?.Users?.profileImage || ProfileImage
                  }
                  alt="ProfileImage"
                  height={34}
                  width={34}
                  className="profileImage"
                />
              </div>
              <span>{userData?.userName || singleBlog?.Users?.userName}</span>
            </div>
            {singleBlog?.isTrending ? (
              <ul>
                <li>Trending</li>
              </ul>
            ) : null}
            <ul>
              <li>{getCurrentFormattedDate()}</li>
            </ul>
          </div>
          <div className="blogImage">
            <img
              src={
                isWriteBlog
                  ? singleBlog?.blogCoverImage
                  : singleBlog?.coverPhoto
              }
              alt="BlogImage"
            />
          </div>
          <div className="details">
            <p>{singleBlog?.shortDescription}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: marked(singleBlog?.description || "-"),
              }}
            />
          </div>
          <div className="line"></div>

          <div className="shareProfileAllContnet">
            <div className="firstColumnAlignment">
              {singleBlog?.FacebookChannelLink ||
              singleBlog?.TwitterChannelLink ||
              singleBlog?.LinkdinChannelLink ||
              singleBlog?.InstagramChannelLink ||
              singleBlog?.YouTubeChannelLink ? (
                <div className="leftContent">
                  <span>Share this:</span>
                  <div className="socialIconAlignment">
                    {singleBlog?.FacebookChannelLink ? (
                      <span
                        onClick={() =>
                          handleLinkClick(singleBlog?.FacebookChannelLink)
                        }
                      >
                        <FacebookIcon />
                      </span>
                    ) : null}
                    {singleBlog?.TwitterChannelLink ? (
                      <span
                        onClick={() =>
                          handleLinkClick(singleBlog?.TwitterChannelLink)
                        }
                      >
                        <TwitterIcon />
                      </span>
                    ) : null}
                    {singleBlog?.LinkdinChannelLink ? (
                      <span
                        onClick={() =>
                          handleLinkClick(singleBlog?.LinkdinChannelLink)
                        }
                      >
                        <LinkdinIcon />
                      </span>
                    ) : null}
                    {singleBlog?.InstagramChannelLink ? (
                      <span
                        onClick={() =>
                          handleLinkClick(singleBlog?.InstagramChannelLink)
                        }
                      >
                        <InstagramIcon />
                      </span>
                    ) : null}
                    {singleBlog?.YouTubeChannelLink ? (
                      <span
                        onClick={() =>
                          handleLinkClick(singleBlog?.YouTubeChannelLink)
                        }
                      >
                        <YouTubeIcon />
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="buttonFlex">
                {keyWords?.length > 0
                  ? keyWords?.map((data, index) => (
                      <button type="button" key={index}>
                        {" "}
                        #{data}{" "}
                      </button>
                    ))
                  : singleBlog?.keyWords?.length > 0 &&
                    singleBlog?.keyWords?.map((data, index) => (
                      <button type="button" key={index}>
                        {" "}
                        #{data}{" "}
                      </button>
                    ))}
              </div>
            </div>
            <div className="profileBox">
              <div className="profile">
                <img
                  src={
                    isWriteBlog
                      ? userData?.profileImage
                        ? userData.profileImage
                        : ProfileLg
                      : singleBlog?.Users?.profileImage
                      ? singleBlog.Users.profileImage
                      : ProfileLg
                  }
                  alt="ProfileImage"
                  height={90}
                  width={90}
                  className="profileImage"
                />
              </div>
              <div>
                <h3>
                  {isWriteBlog
                    ? userData?.userName
                    : singleBlog?.Users?.userName}
                </h3>
                {userData?.shortBio || singleBlog?.Users?.shortBio ? (
                  <p>{userData?.shortBio || singleBlog?.Users?.shortBio} </p>
                ) : null}

                <div className="icons">
                  {userData?.facebookLink || singleBlog?.Users?.facebookLink ? (
                    <span
                      onClick={() =>
                        handleLinkClick(
                          userData?.facebookLink ||
                            singleBlog?.Users?.facebookLink
                        )
                      }
                    >
                      <FacebookWhiteIcon />
                    </span>
                  ) : null}
                  {userData?.twitterLink || singleBlog?.Users?.twitterLink ? (
                    <span
                      onClick={() => handleLinkClick(userData?.twitterLink)}
                    >
                      <TwitterWhiteIcon />
                    </span>
                  ) : null}
                  {userData?.linkedinLink || singleBlog?.Users?.linkedinLink ? (
                    <span
                      onClick={() =>
                        handleLinkClick(
                          userData?.linkedinLink ||
                            singleBlog?.Users?.linkedinLink
                        )
                      }
                    >
                      <LinkedinWhiteIcon />
                    </span>
                  ) : null}
                  {userData?.instagramLink ||
                  singleBlog?.Users?.instagramLink ? (
                    <span
                      onClick={() =>
                        handleLinkClick(
                          userData?.instagramLink ||
                            singleBlog?.Users?.instagramLink
                        )
                      }
                    >
                      <InstagramWhiteIcon />
                    </span>
                  ) : null}
                  {userData?.youtubeLink || singleBlog?.Users?.youtubeLink ? (
                    <span
                      onClick={() =>
                        handleLinkClick(
                          userData?.youtubeLink ||
                            singleBlog?.Users?.youtubeLink
                        )
                      }
                    >
                      <YoutubeWhiteIcon />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
