import { icon } from "leaflet";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [privacyAndPolicy, setPrivacyAndPolicy] = useState(false);
  const [newsLetters, setNewsletters] = useState();
  const [isInvoices, setIsInvoices] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isTools, setIsTools] = useState(false);
  const [isCategoryBlogs, setIsCategoryBlogs] = useState(false);
  const [isBlogsList, setIsBlogsList] = useState(false);
  const [isGpts, setIsGpts] = useState(false);
  const [isGptCategory, setIsGptCategory] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  const updateIconSidebar = (e) => {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        const id = item.getAttribute("subitems");
        if (document.getElementById(id)) {
          document.getElementById(id).classList.remove("show");
        }
      });
    }
  };

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "User") {
      setIsUser(false);
    }
    if (iscurrentState !== "Invoices") {
      setIsInvoices(false);
    }
    if (iscurrentState !== "NewsLetters") {
      setNewsletters(false);
    }
    if (iscurrentState !== "privacyAndPolicy") {
      setPrivacyAndPolicy(false);
    }
    if (iscurrentState !== "ecommerce") {
      setIsEcommerce(false);
    }
    if (iscurrentState !== "blogs") {
      setIsTools(false);
    }
    if (iscurrentState !== "blogsCategory") {
      setIsCategoryBlogs(false);
    }
    if (iscurrentState !== "bloglist") {
      setIsBlogsList(false);
    }
    if (iscurrentState !== "gpts") {
      setIsGpts(false);
    }
    if (iscurrentState !== "gptcategory") {
      setIsGptCategory(false);
    }
  }, [
    iscurrentState,
    isDashboard,
    isUser,
    isInvoices,
    isEcommerce,
    isTools,
    isCategoryBlogs,
    isBlogsList,
    isGpts,
    isGptCategory,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-fill",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: (e) => {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "User",
      label: "User",
      icon: "ri-user-2-fill",
      link: "/user",
      stateVariables: isUser,
      click: (e) => {
        e.preventDefault();
        setIsUser(!isUser);
        setIscurrentState("User");
        updateIconSidebar(e);
      },
    },
    {
      label: "Blogs",
      isHeader: true,
    },

    {
      id: "category",
      label: "Blogs Category",
      parentId: "blogs",
      icon: "ri-git-merge-line",

      stateVariables: isCategoryBlogs,
      click: (e) => {
        e.preventDefault();
        setIsCategoryBlogs(!isCategoryBlogs);
        setIscurrentState("blogsCategory");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "main-category",
          label: "Main Category",
          link: "/blogs-category",
          parentId: "blogsCategory",
        },

      ],
    },
    {
      id: "Bloglist",
      label: "Blogs List",
      stateVariables: isBlogsList,
      icon: "ri-apps-fill",

      click: (e) => {
        e.preventDefault();
        setIsBlogsList(!isBlogsList);
        setIscurrentState("bloglist");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "admin-blogs",
          label: "Admin Blogs",
          link: "/admin-blogs",
          parentId: "bloglist",
        },
        {
          id: "Blogs",
          label: "User Blogs",
          link: "/blogs",
          parentId: "bloglist",
        },
      ],
    },
    {
      id: "privacyAndPolicy",
      label: "Privacy and Policy",
      icon: "ri-lock-fill",
      link: "/privacy-policy",
      stateVariables: privacyAndPolicy,
      click: (e) => {
        e.preventDefault();
        setPrivacyAndPolicy(!privacyAndPolicy);
        setIscurrentState("privacyAndPolicy");
        updateIconSidebar(e);
      },
    },
    {
      id: "NewsLetters",
      label: "News Letters",
      icon: "ri-send-plane-fill",
      link: "/news-letter",
      stateVariables: newsLetters,
      click: (e) => {
        e.preventDefault();
        setNewsletters(!newsLetters);
        setIscurrentState("NewsLetters");
        updateIconSidebar(e);
      },
    },
    //     {
    //       label: "GPT Store",
    //       isHeader: true,
    //     }, {
    //       id: "gptcategory",
    //       label: "GPT Category",
    //       icon: "ri-git-merge-line",

    //       stateVariables: isGptCategory,
    //       click: (e) => {
    //         e.preventDefault();
    //         setIsGptCategory(!isGptCategory);
    //         setIscurrentState("gptcategory");
    //         updateIconSidebar(e);
    //       },
    //       subItems: [
    //       {
    //       id: "gpts-category",
    //       label: "Main Category",
    //       link: "/gpts-category",
    //       parentId: "gptcategory",
    //     },
    //     {
    //       id: "gpts-subcategory",
    //       label: "Sub Category",
    //       link: "/gpts-subcategory",
    //       parentId: "gptcategory",
    //     },
    //       ],
    //     },
    //     {
    //       id: "GPTs",
    //       label: "GPT Lists",
    //       icon: " ri-camera-lens-line",

    //          stateVariables: isGpts,
    //   click: (e) => {
    //     e.preventDefault();
    //     setIsGpts(!isGpts);
    //     setIscurrentState("gpts");
    //     updateIconSidebar(e);
    //       },
    //       subItems: [
       
    //     {
    //       id: "gpts-list",
    //       label: "GPT List",
    //       link: "/gpts",
    //       parentId: "GPTs",
    //     },   {
    //       id: "create-gpt",
    //       label: "Add New GPT's",
    //       link: "/create-gpt",
    //       parentId: "GPTs",
    //     },
    //       ],
    //     },
       
    
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
