import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  del,
  get,
  post,
  postFormData,
  put,
  putFormData,
  thunkHandler,
} from "../../helpers/base";

const initialState = {
  toolData: [],
  toolCount: 0,
  toolCategoryData: [],
  toolCategoryCount: 0,
  toolSubCategoryData: [],
  toolSubCategoryCount: 0,
  toolLoader: false,
};

export const getBlogs = createAsyncThunk(
  "admin-services/dashboard/get-all-blogs",
  async (data, thunkAPI) => {
    https: return await thunkHandler(
      get("admin-services/dashboard/get-all-blogs", data),
      thunkAPI
    );
  }
);
export const deleteBlogs = createAsyncThunk(
  `/blog-services/blogs/delete-blog/id`,
  async (data, thunkAPI) => {
    return await thunkHandler(
      del(`/blog-services/blogs/delete-blog/${data?.id}`),
      thunkAPI
    );
  }
);
export const getBlogsCategory = createAsyncThunk(
  "/blog-categories/get",
  async (data, thunkAPI) => {
    https: return await thunkHandler(
      get("blog-services/blog-categories/get", data),
      thunkAPI
    );
  }
);
export const AddBlogCategory = createAsyncThunk(
  "blog-services/blog-categories/create",
  async (data, thunkAPI) => {
    let payload = {
      "title": data?.title,
      "description": data?.description,
    }
    
    return await thunkHandler(
      post("blog-services/blog-categories/create", payload),
      thunkAPI
    );
  }
);
export const EditBlogCategory = createAsyncThunk(
  "blog-categories/update-category/${id}",
  async (data, thunkAPI) => {
   let UpdateData = {
     title: data?.name,
     description: data?.description,
   };
   
    return await thunkHandler(
      put(
        `blog-services/blog-categories/update/${data?.id}`,
        UpdateData
      ),
      thunkAPI
    );
  }
);
export const getToolsSubCategory = createAsyncThunk(
  "/blog-services/blog-categories/get",
  async (data, thunkAPI) => {
    https: return await thunkHandler(
      get("/blog-services/blog-categories/get", data),
      thunkAPI
    );
  }
);
export const AddToolSubcategory = createAsyncThunk(
  "aiToolSubCategory/add-subCategory",
  async (data, thunkAPI) => {
    return await thunkHandler(
      postFormData("aiToolSubCategory/add-subCategory", data?.body),
      thunkAPI
    );
  }
);
export const EditToolSubcategory = createAsyncThunk(
  "aiToolSubCategory/update-subCategory",
  async (data, thunkAPI) => {
    return await thunkHandler(
      putFormData(
        `aiToolSubCategory/update-subCategory/${data?.id}`,
        data?.body
      ),
      thunkAPI
    );
  }
);
export const updateBlogCategory = createAsyncThunk(
  "admin-services/dashboard/change-blog-category-status/",
  async (data, thunkAPI) => {    
    https: return await thunkHandler(
      put(`admin-services/dashboard/change-blog-category-status/${data?.id}`, {
        isActive: data?.isActive,
      }),
      thunkAPI
    );
  }
);
export const UpdateAiSubCategory = createAsyncThunk(
  "aiToolSubCategory/update-subCategory",
  async (data, thunkAPI) => {
    const formdata = new FormData();
    formdata.append("status", data.status);
    return await thunkHandler(
      putFormData(`aiToolSubCategory/update-subCategory/${data?.id}`, formdata),
      thunkAPI
    );
  }
);
export const DeleteUser = createAsyncThunk(
  "user/deleteUser",
  async (data, thunkAPI) => {
    return await thunkHandler(post("user/deleteUser", data), thunkAPI);
  }
);
export const createBlog = createAsyncThunk(
  "blogs/create",
  async ({ body }, thunkAPI) => {
    return await thunkHandler(postFormData("blog-services/blogs/create", body), thunkAPI);
  }
);
export const updateBlog = createAsyncThunk(
  "blog-services/blogs/update/",
  async ({ body, id }, thunkAPI) => {
    return await thunkHandler(
      postFormData(`blog-services/blogs/update/${id}`, body),
      thunkAPI
    );
  }
);
const ToolSlice = createSlice({
  name: "ToolSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state, action) => {
        state.toolLoader = true;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.toolData = action?.payload?.payload?.blog_category;
        state.toolCount = action?.payload?.payload?.counts;
        state.toolLoader = false;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.toolData = {};
        state.toolLoader = false;
      })
      .addCase(getBlogsCategory.pending, (state, action) => {
        state.toolLoader = true;
      })
      .addCase(getBlogsCategory.fulfilled, (state, action) => {
        state.toolCategoryData = action?.payload?.payload?.blog_category;
        state.toolCategoryCount = action?.payload?.payload?.counts;
        state.toolLoader = false;
      })
      .addCase(getBlogsCategory.rejected, (state, action) => {
        state.toolData = {};
        state.toolLoader = false;
      })
      .addCase(getToolsSubCategory.pending, (state, action) => {
        state.toolLoader = true;
      })
      .addCase(getToolsSubCategory.fulfilled, (state, action) => {
        state.toolSubCategoryData = action?.payload?.payload?.blog_category;
        state.toolSubCategoryCount = action?.payload?.payload?.counts;
        state.toolLoader = false;
      })
      .addCase(getToolsSubCategory.rejected, (state, action) => {
        state.toolData = {};
        state.toolLoader = false;
      })
      .addCase(createBlog.pending, (state, action) => {
        state.toolLoader = true;
      })
      .addCase(createBlog.fulfilled, (state, action) => {
        state.toolLoader = false;
      })
      .addCase(createBlog.rejected, (state, action) => {
        state.toolLoader = false;
      })
      .addCase(updateBlog.pending, (state, action) => {
        state.toolLoader = true;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.toolLoader = false;
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.toolLoader = false;
      });
  },
});

export const {} = ToolSlice.actions;

export default ToolSlice.reducer;
