import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post, put, thunkHandler } from "../../helpers/base";

const initialState = {
  userData: [],
  userCount: 0,

  userLoader:false
};

export const getUsers = createAsyncThunk(
  "/user/get-all-users",
  async ( data, thunkAPI) => {
    return await thunkHandler(
      get("admin-services/dashboard/get-all-users", data),
      thunkAPI
    );
  }
);
export const DeleteUser = createAsyncThunk(
  "user/deleteUser",
  async ( data, thunkAPI) => {
    return await thunkHandler(
      put("admin-services/dashboard/updateUserStatus", data),
      thunkAPI
    );
  }
);

const UsersSlice = createSlice({
  name: "UsersSlice",
  initialState,
  reducers: {
 
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
        state.userLoader = true;

      });
    builder.addCase(getUsers.fulfilled, (state, action) => { 
      state.userData = action?.payload?.payload?.user;
      state.userCount = action?.payload?.payload?.counts;
      state.userLoader = false;

    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.userData = {};
      state.userLoader = false;

    });
  },
});

export const {  } = UsersSlice.actions;

export default UsersSlice.reducer;
