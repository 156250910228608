import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, put, thunkHandler } from "../../helpers/base";

const initialState = {
  privacyPolicyData: [],
  privacyPolicyCount: 0,
  privacyPolicyLoader: false,
};

export const getPrivacyPolicy = createAsyncThunk(
  "admin-services/dashboard/get-all-privacy-policy'",
  async (data, thunkAPI) => {
    https: return await thunkHandler(
      get("admin-services/dashboard/get-all-privacy-policy", data),
      thunkAPI
    );
  }
);
export const updatePrivacyPolicy = createAsyncThunk(
  "admin-services/dashboard/update-privacy-policy",
  async (body, thunkAPI) => {
    return await thunkHandler(
      put(`admin-services/dashboard/update-privacy-policy`, body),
      thunkAPI
    );
  }
);

const PrivacyPolicySlice = createSlice({
  name: "PrivacyPolicy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrivacyPolicy.pending, (state, action) => {
        state.privacyPolicyLoader = true;
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
        state.privacyPolicyData = action?.payload?.payload?.privacy_policy;
        state.privacyPolicyCount = action?.payload?.payload?.counts;
        state.privacyPolicyLoader = false;
      })
      .addCase(getPrivacyPolicy.rejected, (state, action) => {
        state.privacyPolicyData = {};
        state.privacyPolicyLoader = false;
      });
  },
});

export const {} = PrivacyPolicySlice.actions;

export default PrivacyPolicySlice.reducer;
