import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser } from "../../slices/user";
import TableContainer from "../../Components/Common/TableContainer";
import { useMemo } from "react";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Button,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "feather-icons-react/build/IconComponents";
import DeleteModal from "../../Components/Common/DeleteModal";
import Search from "feather-icons-react/build/IconComponents/Search";
import {
  AddBlogCategory,
  EditBlogCategory,
  updateBlogCategory,
  getBlogsCategory,
} from "../../slices/blogs";
import { EditGptsCategory } from "../../slices/gpts";
import { handleSpaceKeyPress } from "../../common/spacePrevent";


const customerstatus = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "inactive" },
];
const BlogsCategory = () => {
  document.title = "Blog Category | WriterTools";

  const dispatch = useDispatch();
  const { toolCategoryCount, toolCategoryData, toolLoader } = useSelector(
    (state) => state.Tool
  );
  const [customerStatus, setcustomerStatus] = useState(customerstatus[0]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userStatus, setuserStatus] = useState({});
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowId, setRowId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [limit, setLimit] = useState(10);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const nPages = Math.ceil(toolCategoryCount / limit);

  const handleOnChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };
  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus]);
  useEffect(() => {
    setSearchValue("");
  }, [customerStatus]);
  useEffect(() => {
    const params = {};

    if (customerStatus?.value && customerStatus?.value !== "All") {
      params.isActive = customerStatus.value === "active"  ? true : false;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getBlogsCategory({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getBlogsCategory({
          ...params,
        })
      );
    }
  }, [customerStatus, searchValue, page, limit]);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const onClickOpenAddModal = () => {
    setAddModal(true);
    setRowId("");
  };
  const onClickDelete = (status) => {
    const data1 = {
      id: status?._id,
      status: status?.isActive 
    };

    setuserStatus(data1);
    setDeleteModal(true);
  };
  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      setValues({ ...values, [event?.target?.name]: file });
      setErrors({ ...errors, icon: "" });
    }
  };
  const handleImageDelete = (name) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <>
            <div className="w-sm">{(page - 1) * 10 + row.row.index + 1}</div>
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: (row) => row?.title ?? "-",

      filterable: false,
    },
    {
      Header: "Description",
      accessor: (row) => row?.description ?? "-",

      filterable: false,
    },
    {
      Header: "Created Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.createdAt)}</>
      ),
    },
    {
      Header: "Updated Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.updatedAt)}</>
      ),
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (cellProps) => {
        switch (cellProps?.row?.original.isActive) {
          case true:
            return (
              <span className="badge text-uppercase badge-soft-success">
                Active
              </span>
            );
          case false:
            return (
              <span className="badge text-uppercase badge-soft-danger">
                Deactive
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                Unknown
              </span>
            );
        }
      },
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "100px" }}>
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-pencil-fill align-bottom me-2 text-muted "
                style={{ fontSize: "21px" }}
                onClick={() => {
                  handleOnEdit(cellProps?.row?.original);
                }}
              ></i>
            </span>

            <Button
              className="btn-sm"
              style={{ paddingY: "0px", margin: "0px" }}
              color={
                cellProps?.row?.original.isActive === true
                  ? "danger"
                  : "success"
              }
              onClick={() => {
                onClickDelete(cellProps?.row?.original);
              }}
            >
              {cellProps?.row?.original.isActive === true
                ? "Deactivate"
                : "Activate"}
            </Button>
          </div>
        );
      },
    },
  ]);

  const handleDeleteCategory = () => {
    setLoader(true);
    dispatch(
      updateBlogCategory({ id: userStatus?.id, isActive: !userStatus?.status })
    )
      .then((res) => {
        if (res?.payload.success === true) {
          if (userStatus?.status !== "active") {
            toast.success("Deactive successfully");
          } else {
            toast.success("Active successfully");
          }
          setDeleteModal(false);
          setLoader(false);
          dispatch(
            getBlogsCategory({
              page : page,
              limit : limit,
              isActive : customerStatus?.value ? customerStatus?.value : null,
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
            })
          );
        } else {
          setLoader(false);

          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        toast.error(err?.data?.message);
      });
  };
  const formValidation = () => {
    let isFormValid = true;
    let newErrors = {};
    const requiredFields = ["title", "description"];

    requiredFields.forEach((field) => {
      if (!values?.title || values?.title.trim() === "") {
        isFormValid = false;
        newErrors["title"] = "Please enter a valid name";
      }
      if (!values?.description || values?.description.trim() === "") {
        isFormValid = false;
        newErrors["description"] = "Please enter a valid description";
      }
    });

    setErrors(newErrors);
    return isFormValid;
  };

  const handleOnEdit = (item) => {
    setRowId(item?._id);
    setValues({
      ...values,
      title: item?.title,
      description: item?.description,
      icon: item?.icon,
    });
    setAddModal(true);
  };
  const handleOnAddCategory = () => {
    if (formValidation()) {
      setLoader(true);
      dispatch(
        AddBlogCategory({
          title: values?.title,
          description: values?.description,
        })
      )
        .then((res) => {
          if (res?.payload?.success) {
            setAddModal(false);
            dispatch(getBlogsCategory({ page: page , limit : limit}));
            setLoader(false);
            toast.success("Category added successfully");
            setValues({});
          } else {
            setLoader(false);
            toast.error(res?.payload?.data?.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.response.data.message);
        });
    }
  };

  const handleOnUpdateCategory = () => {
    if (formValidation()) {
      setLoader(true);

      dispatch(
        EditBlogCategory({
          name: values?.title,
          description: values?.description,
          id: rowId,
        })
      )
        .then((res) => {
          if (res?.payload?.success) {
            setAddModal(false);
            setLoader(false);
            dispatch(getBlogsCategory({ page: page , limit : limit  }));
            toast.success("Category Updated Successfully");
            setValues({});
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.response.data.message);
        });
    }
  };
  return (
    <>
      <Modal isOpen={addModal} centered id="exampleModal">
        <ModalHeader
          toggle={() => {
            setAddModal(false);
            setValues({});
            setErrors({});
          }}
        >
          {rowId ? "Update Blog Category" : "Add Blog Category"}{" "}
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                Category Name: <span className="text-danger">*</span>
                {errors.title && (
                  <span className="text-danger">{errors.title}</span>
                )}
              </label>
              <Input
                type="text"
                className="form-control"
                id="customer-name"
                name="title"
                placeholder="Enter category name"
                value={values.title}
                onChange={handleOnChange}
                onKeyDown={handleSpaceKeyPress}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Category Description: <span className="text-danger">*</span>
                {errors.description && (
                  <span className="text-danger">{errors.description}</span>
                )}
              </label>
              <textarea
                className="form-control"
                id="message-text"
                rows="4"
                name="description"
                placeholder="Enter category description"
                value={values.description}
                onChange={handleOnChange}
                onKeyDown={handleSpaceKeyPress}
              ></textarea>
            </div>
          </form>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setAddModal(false);
              setValues({});
              setErrors({});
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            onClick={() => {
              rowId ? handleOnUpdateCategory() : handleOnAddCategory();
            }}
          >
            {loader && (
              <Spinner size="sm" className="flex-shrink-0  ">
                {" "}
                Loading...{" "}
              </Spinner>
            )}
            <span className="flex-grow-1 ms-2">
              {rowId ? "Update Blog Category" : "Add Blog Category"}
            </span>
          </Button>
        </div>
      </Modal>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          text={userStatus?.status == true ? "Deactivate" : "Activate"}
          onDeleteClick={() => handleDeleteCategory()}
          onCloseClick={() => setDeleteModal(false)}
          loader={loader}
          
        />
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Blog Category</h5>
                  {toolCategoryCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {toolLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {toolCategoryData && toolCategoryData.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={toolCategoryData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          isCustomerFilter={true}
                          customerstatus={customerstatus}
                          setcustomerStatus={setcustomerStatus}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Category..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={toolCategoryCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          iscreated={true}
                          addbuttontext={"Add Category"}
                          onClickOpenAddModal={onClickOpenAddModal}
                          totalDataCount={toolCategoryCount}
                          ispaginationshow={
                            toolCategoryCount > 10 && limit < toolCategoryCount
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={true}
                            customPageSize={0}
                            customerstatus={customerstatus}
                            setcustomerStatus={setcustomerStatus}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-border"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Category..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            iscreated={true}
                            addbuttontext={"Add Category"}
                            onClickOpenAddModal={onClickOpenAddModal}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BlogsCategory;
