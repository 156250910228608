import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser } from "../../slices/user";
import TableContainer from "../../Components/Common/TableContainer";
import { useMemo } from "react";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import { CardBody, Row, Col, Card, CardHeader, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";


import { deleteNewsLetter, getNewsLetter } from "../../slices/newsletter/newsletter";

const customerstatus = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "inactive" },
];
const NewsLetter = () => {
  document.title = "News Letter | WriterTools";

  const dispatch = useDispatch();
  const { newsLetterCount, newsLetterData, newsLetterLoader } = useSelector(
    (state) => state.NewLetter
  );
  const [customerStatus, setcustomerStatus] = useState(customerstatus[0]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userStatus, setuserStatus] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowId, setRowId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [limit, setLimit] = useState(10);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const nPages = Math.ceil(newsLetterCount / limit);

  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus]);
  useEffect(() => {
    setSearchValue("");
  }, [customerStatus]);
  useEffect(() => {
    const params = {};

    if (customerStatus?.value && customerStatus?.value !== "All") {
      params.isActive = customerStatus.value === "active" ? true : false;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getNewsLetter({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getNewsLetter({
          ...params,
        })
      );
    }
  }, [customerStatus, searchValue, page, limit]);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const onClickOpenAddModal = () => {
    setAddModal(true);
    setRowId("");
  };
  const onClickDelete = (status) => {
    const data1 = {
      id: status?._id,
      status: status?.isActive,
    };

    setuserStatus(data1);
    setDeleteModal(true);
  };

  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <>
            <div className="w-sm">{(page - 1) * 10 + row.row.index + 1}</div>
          </>
        );
      },
    },
    {
      Header: "Subscriber Email",
      accessor: (row) => row?.email ?? "-",

      filterable: false,
    },
    {
      Header: "Created Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.createdAt)}</>
      ),
    },

    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "100px" }}>
            <Button
              className="btn-sm"
              style={{ paddingY: "0px", margin: "0px" }}
              color={"danger"}
              onClick={() => {
                onClickDelete(cellProps?.row?.original);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ]);

  const handleDeleteNewsLetter = () => {
    setLoader(true);
    dispatch(deleteNewsLetter({ id: userStatus?.id }))
      .then((res) => {
        if (res?.payload.success === true) {
          toast.success("Deleted successfully");
          setDeleteModal(false);
          setLoader(false);
          dispatch(
            getNewsLetter({
              page,
              isActive: customerStatus?.value ? customerStatus?.value : null,
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
            })
          );
        } else {
          setLoader(false);

          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        toast.error(err?.data?.message);
      });
  };
  return (
    <>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          text={"Delete"}
          onDeleteClick={() => handleDeleteNewsLetter()}
          onCloseClick={() => setDeleteModal(false)}
          loader={loader}
        />
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">News Letter</h5>
                  {newsLetterCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {newsLetterLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {newsLetterData && newsLetterData.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={newsLetterData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Email..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={newsLetterCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          iscreated={true}
                          totalDataCount={newsLetterCount}
                          ispaginationshow={
                            newsLetterCount > 10 && limit < newsLetterCount
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={true}
                            customPageSize={0}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-border"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Subscribers..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            iscreated={true}
                            addbuttontext={"Add Category"}
                            onClickOpenAddModal={onClickOpenAddModal}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewsLetter;
