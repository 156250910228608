//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  adminLogin,
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";
import { toast } from "react-toastify";

export const loginUser = (user, history) => async (dispatch) => {
  try {
    let response;

    response = adminLogin({
      email: user?.email,
      password: user?.password,
      isSocial: false,
    });

    var data = await response;
    if (data) {
      sessionStorage.setItem(
        "authUser",
        JSON.stringify(data?.data?.payload?.user)
      );
      sessionStorage.setItem("UserToken", data?.data?.payload?.tokens);
      localStorage.setItem(
        "authUser", 
        JSON.stringify(data?.data?.payload?.user)
      );
      localStorage.setItem("UserToken", data?.data?.payload?.tokens);
      dispatch(loginSuccess(data?.data?.payload?.user));
        history("/dashboard");

      toast.success("Welcome to the Dashboard");
    }

  } catch (error) {
    toast.error(
      error?.response?.data?.payload?.message ??
        error?.response?.data?.message
    );

    dispatch(
      apiError(
        error?.response?.data?.payload?.message ??
          error?.response?.data?.message
      )
    );
  }
};

export const logoutUser = () => async (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();

  dispatch(logoutUserSuccess(true));
};

export const socialLogin = (type, history) => async (dispatch) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    //  else {
    //   response = postSocialLogin(data);
    // }

    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      history("/dashboard");
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
