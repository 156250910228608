import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser } from "../../slices/user";
import TableContainer from "../../Components/Common/TableContainer";
import { useMemo } from "react";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import {
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { getBlogs } from "../../slices/blogs";
import { useNavigate } from "react-router-dom";
import ImagePreviewModal from "../../Components/Common/ImagePreviewModal";
import { truncateAddress } from "../../Components/Common/TruncateText";
import { ChangeBlogsStatus } from "../../slices/gpts";
import { marked } from "marked";
import BlogPreviewModal from "../../Components/Common/BlogPreviewModal/BlogPreviewModal";

const customerstatus = [
  { label: "All", value: "" },
  { label: "Active", value: true },
  { label: "Deactive", value: "false" },
];
const index = () => {
  const dispatch = useDispatch();
  document.title = "Admin Blogs | WriterTools";

  const { toolCount, toolData, toolLoader } = useSelector(
    (state) => state.Tool
  );
  const BlogRedirectLink = process.env.REACT_APP_BLOG_URL;
  const [customerStatus, setcustomerStatus] = useState(customerstatus[0]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [imageModal, setImageModal] = useState(false);
  const [imageLink, setImgLink] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [userStatus, setuserStatus] = useState({});
  const [profileData, setProfileData] = useState({});
  const [blogDetailModal, setBlogDetailModal] = useState(false);
  const [isTrendingLoader, setIsTrendingLoader] = useState(false);

  const [limit, setLimit] = useState(10);
  const nPages = Math.ceil(toolCount / limit);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus]);
  useEffect(() => {
    setSearchValue("");
  }, [customerStatus]);
  useEffect(() => {
    const params = {};

    if (customerStatus?.value) {
      params.isActive = customerStatus.value;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    params.createdBy = "Admin";

    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getBlogs({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getBlogs({
          ...params,
        })
      );
    }
  }, [customerStatus, searchValue, page, limit]);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const onClickDelete = (status) => {
    const data1 = {
      userid: status?._id,
      isActive: status?.isActive === true ? false : true,
    };

    setuserStatus(data1);
    setDeleteModal(true);
  };
  const handleOnOpenImage = (row) => {
    const data = {
      img: row?.coverPhoto,
      name: row?.title,
    };

    setImgLink(data);
    setImageModal(true);
  };

  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <>
            <div style={{ width: "60px" }}>
              {(page - 1) * 10 + row.row.index + 1}
            </div>
          </>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "createdDate",
      Cell: (cellProps) => (
        <div style={{ width: "160px" }}>
          {handleValidDate(cellProps?.row?.original?.createdAt) ?? "-"}
        </div>
      ),
    },
    {
      Header: "Creator Name",
      Cell: (cellProps) => (
        <div style={{ width: "170px" }}>
          {cellProps?.row?.original?.Users?.userName ?? "-"}
        </div>
      ),
    },
    {
      Header: "Creator Email ID",
      Cell: (cellProps) => (
        <div style={{ width: "180px" }}>
          {cellProps?.row?.original?.Users?.email ?? "-"}
        </div>
      ),
    },
    {
      Header: "Blog Category",
      Cell: (cellProps) => (
        <div style={{ width: "300px" }}>
          {cellProps?.row?.original?.BlogCategory?.length > 0
            ? cellProps?.row?.original?.BlogCategory?.map((item, index) => (
                <span key={index}>{item?.title}, </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      Header: "Cover Photo",
      Cell: (cellProps) => (
        <>
          <div
            className="d-flex align-items-center w-lg"
            onClick={() => handleOnOpenImage(cellProps?.row?.original)}
          >
            <img
              style={{ objectFit: "contain" }}
              src={cellProps?.row?.original?.coverPhoto}
              alt=""
              className="avatar-xs me-2"
            />
          </div>
        </>
      ),
    },
    {
      Header: "Blog Title",
      accessor: (row) => row?.title ?? "-",
      Cell: ({ row }) => (
        <div style={{ width: "290px" }}>
          {row?.original?.status !== "rejected" ? (
            <a
              href={`${BlogRedirectLink}${row?.original?.slugId}`}
              target="_blank"
            >
              <p style={{ "text-transform": "capitalize" }}>
                {row?.original?.title ?? "-"}
              </p>
            </a>
          ) : (
            <>
              <p style={{ "text-transform": "capitalize" }}>
                {row?.original?.title ?? "-"}
              </p>
            </>
          )}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original.title.toLowerCase();
        const b = rowB.original.title.toLowerCase();
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    {
      Header: "Short Description",
      accessor: "sortDescription",
      Cell: (cellProps) => (
        <div style={{ width: "270px" }} className="text-truncate-three-lines">
          {cellProps?.row?.original?.sortDescription}
        </div>
      ),
      sortType: (rowA, rowB) => {
        const a = rowA.original.sortDescription.toLowerCase();
        const b = rowB.original.sortDescription.toLowerCase();
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
    },
    {
      Header: "Keywords",
      Cell: (cellProps) => (
        <div className="w-lg" style={{ width: "200px" }}>
          {cellProps?.row?.original?.keyWords?.length > 0
            ? cellProps?.row?.original?.keyWords?.map((item, index) => (
                <span key={index}>{item}, </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      Header: "Website URL",
      Cell: (cellProps) => (
        <>{truncateAddress(cellProps?.row?.original?.websiteLink ?? "-", 40)}</>
      ),
    },
    {
      Header: "Slug URL",
      Cell: (cellProps) => (
        <div className="text-truncate" style={{ width: "300px" }}>
          {cellProps?.row?.original?.slugId ?? "-"}
        </div>
      ),
    },
    {
      Header: "Status",
      Cell: (cellProps) => {
        switch (cellProps?.row?.original.isActive) {
          case true:
            return (
              <span className="badge text-uppercase badge-soft-success">
                Active
              </span>
            );
          case false:
            return (
              <span className="badge text-uppercase badge-soft-danger">
                Deactive
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                Unknown
              </span>
            );
        }
      },
      filterable: false,
    },
    {
      Header: "Updated Date",
      Cell: (cellProps) => (
        <div className="w-lg">
          {handleValidDate(cellProps?.row?.original?.updatedAt)}
        </div>
      ),
    },
    {
      Header: "Trending",
      Cell: (cellProps) => (
        <div className="w-lg">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck4"
              checked={cellProps?.row?.original?.isTrending}
              onChange={(e) =>
                handleIsTrendingChange(e, cellProps?.row?.original?._id)
              }
            />
          </div>
        </div>
      ),
    },

    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "150px" }}>
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-eye-fill align-bottom me-2 text-muted "
                style={{ fontSize: "23px" }}
                onClick={() => {
                  setProfileData(cellProps?.row?.original);
                  setBlogDetailModal(true);
                }}
              ></i>
            </span>
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-pencil-fill align-bottom me-2 text-muted "
                style={{ fontSize: "21px" }}
                onClick={() => {
                  navigate(
                    `/update-admin-blogs/${cellProps?.row?.original?._id}`
                  );
                }}
              ></i>
            </span>

            <Button
              className="btn-sm"
              style={{ paddingY: "0px", margin: "0px" }}
              color={
                cellProps?.row?.original.isActive === true
                  ? "danger"
                  : "success"
              }
              onClick={() => {
                onClickDelete(cellProps?.row?.original);
              }}
            >
              {cellProps?.row?.original.isActive === true
                ? "Deactivate"
                : "Activate"}
            </Button>
          </div>
        );
      },
    },
  ]);
  const handleIsTrendingChange = (e, TrendingDataId) => {
    const data = {
      isTrending: e.target.checked,
      appIds: TrendingDataId,
    };
    setIsTrendingLoader(true);
    dispatch(ChangeBlogsStatus(data))
      .then((res) => {
        if (res?.payload?.success === true) {
          setIsTrendingLoader(false);
          toast.success("Updated successfully");
          dispatch(
            getBlogs({
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
              status: customerStatus.value ? customerStatus.value : null,
              page: page,
              createdBy: "Admin",
            })
          );
        } else if (res?.payload?.status === 401) {
          toast.info("Session expired. Please log in again.");
          setIsTrendingLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsTrendingLoader(false);
      });
  };
  const handleStatusChanges = () => {
    dispatch(
      ChangeBlogsStatus({
        appIds: userStatus.userid,
        isActve: userStatus.isActive,
        type: "ActiveDeactive",
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          if (userStatus?.isActive === false) {
            toast.error("Deactive successfully");
          } else {
            toast.success("Active successfully");
          }
          setDeleteModal(false);
          dispatch(
            getBlogs({
              isActive: customerStatus.value ? customerStatus.value : null,
              search: searchValue?.trimEnd() ? searchValue?.trimEnd() : null,
              page: page,
              createdBy: "Admin",
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error Deleting User:", error);
      });
  };

  return (
    <>
      <div className="page-content">
        <Modal
          isOpen={blogDetailModal}
          size="xl"
          scrollable={true}
          centered
          id="exampleModal"
        >
          <ModalHeader
            toggle={() => {
              setBlogDetailModal(false);
            }}
          >
            Blog Details
          </ModalHeader>
          <ModalBody>
            <BlogPreviewModal singleBlog={profileData} isWriteBlog={false} />
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="light"
              onClick={() => {
                setBlogDetailModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
        <DeleteModal
          show={deleteModal}
          text={userStatus?.isActive === false ? "Deactive" : "Active"}
          onDeleteClick={() => handleStatusChanges()}
          onCloseClick={() => setDeleteModal(false)}
        />
        <ImagePreviewModal
          show={imageModal}
          src={imageLink?.img}
          text={imageLink.name}
          onCloseClick={() => setImageModal(false)}
        />
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Admin Blogs</h5>
                  {toolCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {toolLoader || isTrendingLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {toolData && toolData?.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={toolData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          isCustomerFilter={true}
                          customerstatus={customerstatus}
                          setcustomerStatus={setcustomerStatus}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-borderless"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Blogs..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={toolCount > 10 ? true : false}
                          addbuttontext={"Add Blogs"}
                          navigateTo={"/create-blog"}
                          iscreated={true}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          totalDataCount={toolCount}
                          ispaginationshow={
                            toolCount > 10 && limit < toolCount ? true : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            isCustomerFilter={true}
                            customPageSize={0}
                            customerstatus={customerstatus}
                            setcustomerStatus={setcustomerStatus}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-borderless"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Blogs..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            addbuttontext={"Add Blogs"}
                            navigateTo={"/create-blog"}
                            iscreated={true}
                            isPagination={false}
                          />
                        </>
                      )}
                    </>
                  )}

                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default index;
